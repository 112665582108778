import React from 'react'
import Header from '../components/UI/Header'
import Head from '../components/UI/Head'
import Footer from '../components/UI/Footer';

const GoogleAds = () => {    
  return (
    <>
      <Head 
          title="Google Ads Services | Boost Your Business with It's Local Marketing"
          description="Maximize your online presence with It's Local Marketing's expert Google Ads services. Our team creates effective ad campaigns to drive traffic, increase conversions, and boost your business growth. Contact us today!"
          keywords="Google Ads services, online advertising, PPC, pay-per-click, ad campaigns, digital marketing, boost business, It's Local Marketing"
      />
      <Header />
        
      {/* Sections */}


      <Footer />
    </>
  );
};

export default GoogleAds;