import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../src/store/actions/dataActions";
import "./App.css";
import { RootState } from "./store/index";
import { Route, Routes } from "react-router-dom";

/* Elements */
import Preloader from "./components/UI/Preloader";
import CustomCursor from "./components/tools/CustomCursor";

/* Pages Definition */
import Home from "./pages/Home";
import GoogleAds from "./pages/GoogleAds";
import WebDevelopment from "./pages/WebDevelopment";
import OurWork from "./pages/OurWork";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import { fetchWpPosts } from "./store/actions/wpActions";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Simulate loading time

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const error = useSelector((state: RootState) => state.wp.error);
  const wpPosts = useSelector((state: RootState) => state.wp.posts);

  useEffect(() => {
    dispatch(fetchWpPosts());
  }, [dispatch]);

  if (loading) return <Preloader />; // Remove the progress prop
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      {/* Define routes */}
      <main>
        <CustomCursor />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/google-ads" element={<GoogleAds />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/portfolio" element={<OurWork />} />
          <Route path="/blog" element={<Blog wpPosts={wpPosts} />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
