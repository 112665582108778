import React from 'react'

const Hero = () => {
  return (
    <section className='h-[200vh] px-[20px] pt-[100px]  lg:pt-[140px] lg:px-[100px]'>
      <div className='flex flex-col'>
        <div className='w-full relative flex flex-wrap'>
          <div className='absolute top-0 right-0 flex h-full z-20 py-4 px-6 | lg:py-6 lg:px-10'>
            <div className='sticky bottom-6 left-0 self-end'>

            </div>
          </div>
          <div className='px-2 | lg:px-3 | xl:px-4 w-full relative'>

          </div>
          <div className='px-2 | lg:px-3 | xl:px-4 absolute -left-3 top-0 z-20 flex flex-col items-start pb-8 w-auto | lg:left-20 | xl:left-40'>

          </div>
          <h1 className='lg:text-[70px] lg:leading-[84px] lg:pr-[100px]'>Digital Marketing Agency in Victoria</h1>
        </div>
        <div>
      </div>
        
    </div>
    </section>
    
  )
}

export default Hero