import React from 'react';
import Helmet from "react-helmet";

interface HeadProps {
    title?: string;
    description?: string;
    keywords?: string;
  }

  const Head: React.FC<HeadProps> = ({ title, description, keywords }) => {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default Head;