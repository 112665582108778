import axios from 'axios';
import { Dispatch } from 'redux';
import {
  FETCH_WP_POSTS_REQUEST,
  FETCH_WP_POSTS_SUCCESS,
  FETCH_WP_POSTS_FAILURE,
} from './wpActionTypes';

export const fetchWpPosts = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_WP_POSTS_REQUEST });

    try {
      const response = await axios.get('https://website-68483f2e.ebr.fux.mybluehost.me/wp-json/wp/v2/posts');
      dispatch({ type: FETCH_WP_POSTS_SUCCESS, payload: response.data });
    } catch (error: any) {
      dispatch({ type: FETCH_WP_POSTS_FAILURE, error: error.message });
    }
  };
};
