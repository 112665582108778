import React from 'react'
import Logo from "../../assets/images/logo.svg";
import Chamber from "../../assets/images/thechamber.jpg";
import GooglePartners from "../../assets/images/google-partners.webp";

const Footer = () => {
  return (
    <footer className="bottom-0 w-full | lg:px-[40px] ">
      <div className="w-full flex flex-col gap-[20px] rounded-t-[45px] header px-[20px] pt-[40px] pb-[30px] | md:px-[40px] | lg:py-[40px]">
        <div className="w-full flex flex-col gap-[30px] | lg:gap-[50px] lg:flex-row">
          
          {/* First Column */}
          <div className="lg:w-[40%] 2xl:w-[55%]">
            <img src={Logo} className="w-[140px] lg:w-[160px]" alt="Logo" />
            <p className="text-[13px] leading-[22px] mt-[15px] | md:w-full | lg:mt-[20px] | 2xl:w-2/3">
              We offers a comprehensive suite of digital marketing services that
              cover all aspects of our online presence. From SEO and social media
              marketing to content creation and PPC advertising, they have the
              expertise and resources to handle our diverse marketing needs.
            </p>
  
            {/* Partners */}
            <div className="flex flex-row mt-[20px] gap-[20px] items-center">
              <a
                href="https://www.google.com/partners/agency?id=3942381610"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePartners}
                  className="w-[70px]"
                  alt="Google Partner"
                />
              </a>
              <a
                href="https://web.victoriachamber.ca/Advertising-Advertising-Agencies/It%27s-Local-Marketing-11491"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Chamber}
                  className="h-[70px]"
                  alt="The Chamber Member"
                />
              </a>
            </div>
          </div>
  
          {/* Second Column */}
          <div className="flex flex-col lg:w-[20%]">
            <h3 className="font-semibold text-[18px] mb-[10px] | lg:text-[20px]">Navigation</h3>
            <div className="flex flex-col gap-[8px]">
              <a
                href="/services"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Services
              </a>
              <a
                href="/about-us"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                About
              </a>
              <a
                href="/portfolio"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Case Study
              </a>
              <a
                href="/blog"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Blog
              </a>
              <a
                href="/contact"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Contact
              </a>
            </div>
          </div>
  
          <div className="flex flex-col lg:w-[20%]">
            <h3 className="font-semibold text-[18px] mb-[10px] | lg:text-[20px]">License</h3>
            <div className="flex flex-col gap-[8px]">
              <a
                href="/privacy-policy"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Privacy Policy
              </a>
              <a
                href="/copyrighting"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Copyrighting
              </a>
              <a
                href="/terms-of-service"
                className="hover:text-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                Term of Service
              </a>
            </div>
          </div>
  
          <div className="lg:w-[20%]">
            <h3 className="font-semibold text-[18px] mb-[10px] | lg:text-[20px]">Information</h3>
            <div className="flex flex-col gap-[10px]">
              {/* Phone Number Link */}
              <a
                href="tel:+1(236)991-8322"
                target="_blank"
                rel="noreferrer"
                className="hover:text-accent hover:fill-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                <div className="flex flex-row gap-[10px] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="16"
                    height="16"
                  >
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                  <p>+1 (236) 991-8322</p>
                </div>
              </a>
  
              {/* Email Link */}
              <a
                href="mailto:hello@itslocalmarketing.com"
                target="_blank"
                rel="noreferrer"
                className="hover:text-accent hover:fill-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                <div className="flex flex-row gap-[10px] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="16"
                    height="16"
                  >
                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                  </svg>
                  <p>hello@itslocalmarketing.com</p>
                </div>
              </a>
  
              {/* Locations */}
              <a
                href="https://maps.app.goo.gl/1ffCXyqGYdgY7jWV7"
                target="_blank"
                rel="noreferrer"
                className="hover:text-accent hover:fill-accent ease-in duration-200 text-[14px] | lg:text-[15px]"
              >
                <div className="flex flex-row gap-[10px] items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    width="16"
                    height="16"
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  <p>
                    211-2840 Peatt Rd.
                    <br />
                    Victoria, BC V9B 3V4
                    <br />
                    Canada
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
  
        <div className="flex w-full flex-col-reverse mt-[10px] | lg:mt-[30px] lg:flex-row">
          {/* Rights Reserved */}
          <div className="flex items-center justify-center w-full mt-[20px] | md:justify-start md:mt-0 md:w-1/2">
            <p className="text-[14px] tracking-wide leading-[22px]">
              © 2024 All rights reserved.
            </p>
          </div>
  
          {/* Social Links */}
          <div className="flex w-full gap-[10px] mt-[20px] justify-center | md:w-1/2 md:justify-end mt-0 | lg:flex-row">
            {/* Linkedin Icon */}
            <a
              href="https://www.linkedin.com/company/itslocalmarketing/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-black hover:bg-accent fill-white ease-in duration-200 p-[16px] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height="16"
                  width="16"
                >
                  <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                </svg>
              </div>
            </a>
  
            {/* Facebook Icon */}
            <a
              href="https://www.facebook.com/itslocalmarketing"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-black hover:bg-accent fill-white ease-in duration-200 p-[16px] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  height="16"
                  width="16"
                >
                  <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                </svg>
              </div>
            </a>
  
            {/* Instagram Icon */}
            <a
              href="https://www.instagram.com/itslocalmarketing"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-black hover:bg-accent fill-white ease-in duration-200 p-[16px] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height="16"
                  width="16"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
  
}

export default Footer