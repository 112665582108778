import {
    FETCH_WP_POSTS_REQUEST,
    FETCH_WP_POSTS_SUCCESS,
    FETCH_WP_POSTS_FAILURE,
  } from '../actions/wpActionTypes';
  
  const initialState = {
    loading: false,
    posts: [],
    error: '',
  };
  
  const wpReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FETCH_WP_POSTS_REQUEST:
        return { ...state, loading: true };
      case FETCH_WP_POSTS_SUCCESS:
        return { ...state, loading: false, posts: action.payload };
      case FETCH_WP_POSTS_FAILURE:
        return { ...state, loading: false, error: action.error };
      default:
        return state;
    }
  };
  
  export default wpReducer;
  