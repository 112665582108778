import React from 'react'
import Head from '../components/UI/Head';
import Header from '../components/UI/Header';
import "../App.css";
import Footer from '../components/UI/Footer';

interface BlogProps {
  wpPosts: any[];
}


const Blog: React.FC<BlogProps> = ({ wpPosts }) => {
  return (
    <>
      <Head
          title="Blog | It's Local Marketing"
          description="Read the latest insights and updates on digital marketing, web development, and more from It's Local Marketing. Stay informed with our expert blog posts."
          keywords="blog, digital marketing, web development, SEO, PPC, It's Local Marketing"
      />
      <Header />
      <ul>
        {wpPosts.map(post => (
          <li key={post.id}>
            <h2>{post.title.rendered}</h2>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </li>
        ))}
      </ul>
      <Footer />
    </>
  );
};

export default Blog