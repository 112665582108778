import React from 'react'
import Header from '../components/UI/Header'
import Head from '../components/UI/Head'
import Footer from '../components/UI/Footer'

const OurWork = () => {
  return (
    <>
      <Head 
          title="Portfolio | It's Local Marketing"
          description="Explore our portfolio showcasing successful paid advertising campaigns and innovative web design/development projects. See how It's Local Marketing drives results and delivers exceptional digital experiences."
          keywords="portfolio, paid advertising, web design, web development, digital marketing, marketing agency, It's Local Marketing, case studies, successful campaigns"
      />
      <Header />
      {/* Page Sections */}

      <Footer />
    </>
  )
}

export default OurWork