import React from 'react'
import Header from '../components/UI/Header'
import Head from '../components/UI/Head'
import Hero from '../components/Pages/HomePage/Hero'
import Footer from '../components/UI/Footer'

const Home = () => {
  return (
    <>
      <Head
        title="Digital Marketing Agency for Local Businesses"
        description="Drive the Right Traffic for More Leads & Revenue. Grow Your Local Business With Local Leads — Facebook & Google Partner."
        keywords="marketing agency, ppc, google ads, web development, website, marketing"
      />
      <Header />

        {/* Sections */}
        <Hero />
        

      <Footer />
    </>
    
  )
}

export default Home