import React, { useState, useEffect } from 'react';
import '../../App.css';

const CustomCursor: React.FC = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        const updatePosition = (e: MouseEvent) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        const handleMouseEnter = () => setIsHovering(true);
        const handleMouseLeave = () => setIsHovering(false);

        document.addEventListener('mousemove', updatePosition);
        document.querySelectorAll('.custom-cursor-hover').forEach(element => {
            element.addEventListener('mouseenter', handleMouseEnter);
            element.addEventListener('mouseleave', handleMouseLeave);
        });

        return () => {
            document.removeEventListener('mousemove', updatePosition);
            document.querySelectorAll('.custom-cursor-hover').forEach(element => {
                element.removeEventListener('mouseenter', handleMouseEnter);
                element.removeEventListener('mouseleave', handleMouseLeave);
            });
        };
    }, []);

    return (
        <div
            className={`custom-cursor ${isHovering ? 'is-hovering' : ''}`}
            style={{ left: `${position.x}px`, top: `${position.y}px` }}
        >
            <div className="inner-cursor">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 32 32" fill="none">
                    <path d="M26.2298 1.86291C26.2291 1.98318 26.2047 2.10243 26.158 2.21385C26.1113 2.32527 26.0432 2.42667 25.9577 2.51226C25.8721 2.59784 25.7707 2.66594 25.6592 2.71264C25.5478 2.75934 25.4286 2.78374 25.3083 2.78444L4.22185 2.91928L30.9603 29.6578C31.1309 29.8283 31.2258 30.0604 31.2242 30.3032C31.2227 30.5459 31.1248 30.7793 30.9521 30.952C30.7793 31.1247 30.5459 31.2226 30.3032 31.2242C30.0605 31.2257 29.8283 31.1308 29.6578 30.9603L2.91933 4.2218L2.7845 25.3083C2.78373 25.4285 2.75928 25.5477 2.71255 25.6591C2.66583 25.7705 2.59773 25.8719 2.51216 25.9575C2.42659 26.043 2.32521 26.1111 2.21382 26.1578C2.10243 26.2046 1.98321 26.229 1.86296 26.2298C1.74272 26.2306 1.6238 26.2076 1.513 26.1623C1.40219 26.117 1.30168 26.0502 1.2172 25.9657C1.13271 25.8812 1.06591 25.7807 1.0206 25.6699C0.975296 25.5591 0.952372 25.4402 0.953141 25.32L1.10211 2.02359C1.10281 1.90332 1.12721 1.78407 1.17391 1.67265C1.22062 1.56123 1.28871 1.45983 1.37429 1.37424C1.45988 1.28866 1.56128 1.22056 1.6727 1.17386C1.78412 1.12716 1.90337 1.10276 2.02364 1.10205L25.32 0.953089C25.4403 0.952253 25.5592 0.975132 25.6701 1.02041C25.7809 1.0657 25.8814 1.1325 25.9659 1.217C26.0504 1.30149 26.1172 1.40203 26.1625 1.51286C26.2078 1.62369 26.2307 1.74264 26.2298 1.86291Z" fill="white"/>
                </svg>
            </div>
        </div>
    );
};

export default CustomCursor;