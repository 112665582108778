import React, { useState, useEffect } from 'react';
import "../../App.css"

const Preloader: React.FC = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return oldProgress + 1;
            });
        }, 20); // Adjust the interval speed as needed

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="preloader">
            <div className="preloader-content">
                <div className="percentage">{progress}%</div>
            </div>
        </div>
    );
};

export default Preloader;
