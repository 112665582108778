import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import Logo from "../../assets/images/logo.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Hook to get the current location

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    // Clean up on component unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuOpen]);

  const getLinkClass = (path: string) => {
    return location.pathname === path ? 'running-underline active' : 'running-underline';
  };

  return (
    <header className="w-full flex justify-center fixed top-0 flex items-center z-10 transition-transform duration-300 pt-[5px] px-[20px] | lg:px-[100px] lg:pt-[10px]">
      <div
        className={`flex justify-between items-center header backdrop-blur-xl rounded-full py-[20px] px-[20px] w-full | lg:px-[40px]
        ${isMenuOpen && "header-mob backdrop-blur-xl shadow-lg"}`}
      >
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <a href="/">
            <img src={Logo} alt="Logo" className="w-[140px] md:w-[160px]" />
          </a>
        </div>

        {/* Burger Menu (Mobile) */}
        <div className="md:hidden flex">
          {isMenuOpen ? (
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none"
            >
              {/* Close Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none"
            >
              {/* Burger Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          )}
        </div>

        {/* Right Side Items (Desktop) */}
        <div className="hidden md:flex items-center space-x-6">
          {/* Navigation */}
          <nav className="space-x-6">
            <a
              href="/"
              className={`${getLinkClass("/")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Home
            </a>
            <a
              href="/google-ads"
              className={`${getLinkClass("/google-ads")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Google Ads
            </a>
            <a
              href="/web-development"
              className={`${getLinkClass("/web-development")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Web Development
            </a>
            <a
              href="/portfolio"
              className={`${getLinkClass("/portfolio")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Our Work
            </a>
            <a
              href="/blog"
              className={`${getLinkClass("/blog")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Blog
            </a>
            <a
              href="/contact"
              className={`${getLinkClass("/contact")} hover:text-accent duration-200`}
              onClick={closeMenu}
            >
              Contact
            </a>
          </nav>

          {/* CTA Buttons */}
        </div>

        {/* CTA Button */}
        <div className="space-x-4 hidden lg:flex">
          {/* Sign Up Button */}
          <a href="/contact">
            <button
              className="tracking-wide bg-accent hover:bg-accent-hover text-white hover:text-white hover:border-accent-hover px-auto w-auto lg:px-[35px] lg:py-[15px] py-2 rounded-full duration-300"
              onClick={closeMenu}
            >
              Start a project
            </button>
          </a>
        </div>

        {/* Mobile Menu (Hidden by Default) */}
        {isMenuOpen && (
          <div className="flex flex-col w-full backdrop-blur-lg header-mob-bg shadow-lg z-10 md:hidden absolute top-16 right-0 left-0 pt-4 pb-[60px] px-[20px] items-start space-y-4 h-auto transition-transform duration-300">
            {/* Navigation (Mobile) */}
            <nav className="space-y-4 flex flex-col text-[18px] mb-[30px]">
              <a
                href="/"
                className={`${getLinkClass("/")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Home
              </a>
              <a
                href="/google-ads"
                className={`${getLinkClass("/google-ads")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Google Ads
              </a>
              <a
                href="/web-development"
                className={`${getLinkClass("/web-development")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Web Development
              </a>
              <a
                href="/portfolio"
                className={`${getLinkClass("/portfolio")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Our Work
              </a>
              <a
                href="/blog"
                className={`${getLinkClass("/blog")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Blog
              </a>
              <a
                href="/contact"
                className={`${getLinkClass("/contact")} hover:text-accent duration-200`}
                onClick={closeMenu}
              >
                Contact
              </a>
            </nav>

            {/* CTA Button (Mobile) */}
            <div className="flex flex-row space-x-[20px]">
              {/* CTA Button */}
              <a href="/sign-up">
                <button
                  className="flex justify-center  bg-accent hover:bg-accent-hover text-white w-[150px] py-2 rounded-full"
                  onClick={closeMenu}
                >
                  Start a project
                </button>
              </a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;