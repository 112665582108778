import React from 'react'
import Head from '../components/UI/Head'
import Header from '../components/UI/Header'
import Footer from '../components/UI/Footer';

const Contact = () => {
  return (
    <>
        <Head 
            title="Contact Us | It's Local Marketing | Get in touch"
            description="Get in touch with It's Local Marketing for expert digital marketing services. Contact us today to discuss your needs and find out how we can help your business grow."
            keywords="contact, marketing agency, digital marketing services, get in touch, It's Local Marketing, business inquiries, contact information"
        />
        <Header />
        {/* Sections Definitions */}

        <Footer />
    </>
  );
};

export default Contact