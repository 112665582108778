import React from 'react'
import Header from '../components/UI/Header'
import Head from '../components/UI/Head'
import Footer from '../components/UI/Footer';

const WebDevelopment = () => {
  return (
    <>
      <Head 
        title="Web Development | It's Local Marketing"
        description="Let’s turn your vision into reality. Contact us today to see how we can elevate your online presence and drive more customers to your door!"
        keywords="web development services, custom websites, professional web development, website design, web development agency, It's Local Marketing"
      />
      <Header />

      {/* Sections */}
      

      <Footer />
    </>
  );
};

export default WebDevelopment